import { DEFAULT_NEW_QUERY_ID } from "../../../constants";
import { SuportedObject } from "../../../utils/filterListByFilters";
import { UserDictionary, defaultUser } from "../../../models/user";

export interface DayStat extends SuportedObject {
	id: string;
	title: string;
	description: string;
	type: 'boolean' | 'numeric';
	status: 'ACTIVE' | 'DISABLED';
	target: boolean | number | null;
	targetNumeric: number;
	createdDate: string,
	createdBy: string,
	createdByEmail: string,
};


export interface DayStatDictionary {
	[key: DayStat['id']]: DayStat
};

export const defaultDayStat: DayStat = {
	id: '',
	title: '',
	description: '',
	type: 'boolean',
	status: 'DISABLED',
	target: null,
	targetNumeric: 0,
	createdDate: '',
	createdBy: '',
	createdByEmail: '',
};

export const mapDayStatForApi = (queryId: string, dayStat: DayStat): any => {
	let payload: { [key: string]: any } = {
		id: dayStat.id,
		title: dayStat.title,
		description: dayStat.description,
		type: dayStat.type.toLocaleLowerCase(),
		status: dayStat.status,
		target: dayStat.target,
		createdDate: dayStat.createdDate,
		createdBy: dayStat.createdBy,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = dayStat.id
	}
	return payload;
};

export const mapDayStatFromAPI = (source: any, userDictionary: UserDictionary) => {
	const foundUser = userDictionary[source.createdBy] || { ...defaultUser };
	let numericTarget = 0;
	if (typeof source.target === 'boolean') {
		numericTarget = source.target ? 1 : 0;
	}
	if (typeof source.target === 'number') {
		numericTarget = source.target;
	}
	if (source.target === null) {
		numericTarget = 0;
	}
	return {
		id: source.id,
		title: source.title,
		description: source.description,
		type: source.type,
		status: source.status,
		target: source.target,
		targetNumeric: numericTarget,
		createdDate: source.createdDate,
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		color: source.color
	} as DayStat;
};

export const mapDayStatsFromAPI = (sources: any[], userDictionary: UserDictionary): DayStat[] => {
	return sources.map((source) => mapDayStatFromAPI(source, userDictionary));
};